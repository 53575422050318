import React, {useEffect, useState, useContext} from "react";
import { collection, getDocs, doc, setDoc ,query, where} from "firebase/firestore";
import {db} from '../Firebase/FirebaseConfig'
import './Assessment.css'

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grow from '@mui/material/Grow';

import ChargePanel from "../Components/ChargePanel/ChargePanel";
import CompletedAssessment from "../Components/CompletedAssessment/CompletedAssessment";
import FormQuestions from "../Components/QuestionsForms/FormQuestions";
import MessageAvatarForm from "../Components/QuestionsForms/MessageAvatarForm"
import Avatar from "../Components/Avatar/Avatar";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Camera from "../Components/Camera/Camera";
import Timer from "../Components/Timer/Timer";

import { auth } from '../Firebase/FirebaseConfig';
import { signOut } from 'firebase/auth'
import { AuthContext } from '../Components/Authentication/Auth';
import { maxHeight } from "@mui/system";

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function Assessment() {

  const { currentUser } = useContext(AuthContext);

  const [data_user, set_data_user] = useState({respuestas:[], video:'', finish: false})

  const [show_message, set_show_message] = useState(true);
  const [preguntas_cerradas, set_preguntas_cerradas] = useState(null);
  const [mensaje_bienvenida, set_mensaje_bienvenida] = useState(null);
  const [segundos_maximo_video, set_segundos_maximo_video] = useState(0);

  const [message_avatar, set_message_avatar] = useState('');
  const [questions_completed, set_questions_completed] = useState(false);
  const [avatar_loaded, set_avatar_loaded] = useState(false);
  const [active_camera, set_active_camera] = useState(false);

  const [total_score, set_total_score] = useState(0);
  const [mensaje_cierre, set_mensaje_cierre] = useState('');
  const [mensaje_pregunta_abierta, set_mensaje_pregunta_abierta] = useState('');
  const [mensaje_final, set_mensaje_final] = useState('');

  const [finish, set_finish] = useState(false);
  const [already_completed, set_already_completed] = useState(false);

  useEffect(()=>{
    const obenerDatos = async()=>{
      const UsersData = await getDocs(collection(db,'usuarios_app'));
      
      if(UsersData.docs.length>0){
        const user = UsersData.docs.find(element => element.id == currentUser.uid);
        // console.log("user",user.data());
        if(user){
          if(user.data().finish){
            console.log("user",user.data().finish);
            set_already_completed(true);
          }
        }
      }

      const data_questions_firebase = await getDocs(collection(db,'cuestionarios'));
      const data_questions = data_questions_firebase.docs[0].data();
      // downloadObjectAsJson(data_questions, 'copia_json_preguntas_assessment');

      set_mensaje_bienvenida(data_questions.mensaje_bienvenida)
      set_preguntas_cerradas(data_questions.preguntas_cerradas);
      set_mensaje_cierre(data_questions.mensaje_cierre);
      set_mensaje_pregunta_abierta(data_questions.mensaje_pregunta_abierta);
      set_mensaje_final(data_questions.mensaje_final);
      set_segundos_maximo_video(data_questions.segundos_maximo_video);
    }

    obenerDatos();
  }, [])

  // Funcion para descargar los datos como un json******************************************************************
  // function downloadObjectAsJson(exportObj, exportName){
  //   var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
  //   var downloadAnchorNode = document.createElement('a');
  //   downloadAnchorNode.setAttribute("href",     dataStr);
  //   downloadAnchorNode.setAttribute("download", exportName + ".json");
  //   document.body.appendChild(downloadAnchorNode); // required for firefox
  //   downloadAnchorNode.click();
  //   downloadAnchorNode.remove();
  // }
  // Funcion para descargar los datos como un json******************************************************************

  const onCloseChargePanel = () => {
    set_message_avatar(mensaje_bienvenida);
  }

  const onMessageSubmit = () =>{
    if(finish){
      // window.location.reload(false);
      window.stopSpeech();

      let answers = data_user; 
      answers.finish =true;
      // answers.usuario = currentUser.email;
      set_data_user(answers);

      setDoc(doc(db, "usuarios_app", currentUser.uid), answers, { merge: true }).then(()=>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
      });

    }else{
      if(questions_completed){
        window.stopSpeech();
        set_active_camera(true);
      }else{
        window.stopSpeech();
        set_show_message(false);
      }
      
    }
  }

  const onAnsweredQuestions = (score, resp) =>{
    let new_data = {...data_user};
    new_data.respuestas = resp;
    set_data_user(new_data);
    console.log('data', new_data);

    if(score >= preguntas_cerradas.puntaje_exito){
      set_message_avatar(mensaje_pregunta_abierta);
      // set_active_camera(true);
    }else{
      set_message_avatar(mensaje_cierre);
      set_finish(true);
    }

    set_questions_completed(true);
    set_show_message(true);
  }

  const onFinishAssessmentCamera = (videoUrl) =>{
    
    let answers = data_user; 
    answers.video = videoUrl;
    answers.finish =true;
    // answers.usuario = currentUser.email;
    set_data_user(answers);

    setDoc(doc(db, "usuarios_app", currentUser.uid), answers, { merge: true }).then(()=>{
      set_finish(true);
      set_active_camera(false);
      set_message_avatar(mensaje_final);
    });

    // const cityRef = doc(db, 'usuarios_app', 'BJ');
    // setDoc(cityRef, { capital: false }, { merge: true });
  }

  return (
    <div id="o-assessment-container">
      <Header></Header>
      {already_completed?
        <CompletedAssessment></CompletedAssessment>
        :
        <Container maxWidth="lg" 
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center',
            width:'90%',
            height:'75%'
          }}>
          {/* <Grow 
            in={true} 
            style={{ transformOrigin: '0 0 0' }}
            {...({ timeout: 1000 })}>
            <h2>Hello</h2>
          </Grow> */}
          
          <ChargePanel 
            success={preguntas_cerradas!=null && mensaje_bienvenida!=null && avatar_loaded}
            onCloseChargePanel={onCloseChargePanel}>
          </ChargePanel>
          
          {preguntas_cerradas && mensaje_bienvenida && (
            <>
            {/* <Timer seconds={20} onTimeFinalized={()=>console.log('tiempo terminado')}></Timer> */}
            <Grid style={{display:active_camera?'none':'flex', height:'100%'}} container spacing={3} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm container style={{display: show_message?'flex' : 'none'}}>
                <Avatar 
                  set_avatar_loaded={set_avatar_loaded}
                  message={message_avatar}>
                </Avatar>
              </Grid>

              <Grid id='o-container-preguntas' sm container 
                sx={{height:'100%', overflow:'auto', '@media all and (orientation: portrait)' : {
                  mt:2,
                  height: 'auto'
                }}} 
                item xs={12} 
                direction="column">
                <Container maxWidth="sm" sx={{height:'100%'}}>
                  {show_message?
                    <MessageAvatarForm 
                      // style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}
                      message={message_avatar} 
                      onMessageSubmit={onMessageSubmit}
                      finish={finish}>
                    </MessageAvatarForm> 
                    :
                    <FormQuestions 
                      questions={preguntas_cerradas} 
                      onAnsweredQuestions = {onAnsweredQuestions}>
                    </FormQuestions>
                  }
                  
                </Container>
              </Grid>
            </Grid> 
            <Camera visible={active_camera} segundos_maximo_video={segundos_maximo_video} onFinishAssessmentCamera={onFinishAssessmentCamera}></Camera>
            </>
            )}
          
          
          
        </Container>
      }
      
      <Footer></Footer>
    </div>
    
  );
}

export default Assessment;
