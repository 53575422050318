import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from "react-router";
import { auth } from '../Firebase/FirebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from '../Components/Authentication/Auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from '../Components/Copyright/Copyright';


const theme = createTheme();

const Login = ({ history }) =>{

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const email = data.get('email');
      const password = data.get('password');
      try {
        await signInWithEmailAndPassword(auth, email, password);

        history.push("/assessment");
      } catch (error) {
        alert("Lo sentimos, \nEl usuario y/o la contraseña no son correctos. \nInténtalo nuevamente.");
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/assessment" />;
  }

  return (
    
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Images/Eficacia_Back_2.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/Images/Logotipo_Scenario.png`} height="80" alt=""/>
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Typography component="h1" variant="h5">
              INICIO DE SESIÓN
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="CORREO ELECTRÓNICO"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="CONTRASEÑA"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Alert severity="warning" color="info">
                Para realizar la prueba conéctate desde dispositivos Android o Windows. 
                Utiliza una red WiFi para una mejor experiencia.
              </Alert>
              <Button
                type="submit"
                fullWidth
                variant="contained" //outlined
                sx={{ mt: 3, mb: 2 }}
              >
                Iniciar sesión
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/Images/LOGOS-02.png`} height="50" alt="" style={{marginTop:'1rem'}}/>
              <Copyright sx={{ mt: 1 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    
  );
}

export default withRouter(Login);