import React from "react";

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import EngineeringIcon from '@mui/icons-material/Engineering';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChargePanel = ({success, onCloseChargePanel})=>{
    const [open, setOpen] = React.useState(true);

    const buttonSx = {
        ...(success && {
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[700],
        },
        }),
    };

    const handleClose = () => {
        setOpen(false);
        onCloseChargePanel();
    };


    return (
        <div style={{position:'absolute'}}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={true}
                fullWidth={true}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    zIndex: 10000,
                    width:'100vw'
                }}
                
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                {/* style={{position:'absolute', width:'100vw', height:'100vh'}} */}
                <Container maxWidth="sm" 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent:'center',
                        height:'100%'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Fab
                                aria-label="save"
                                color="primary"
                                sx={buttonSx}>
                                {success ? <CheckIcon /> : <EngineeringIcon />}
                            </Fab>
                            {!success && (
                                <CircularProgress
                                    size={68}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: -6,
                                        left: -6,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    
                    <Button sx={{ mt: 3}} onClick={handleClose} variant="outlined" disabled={!success}>
                        {success?'Continuar':'Cargando...'}
                    </Button>
                </Container>
                
                
            </Dialog>
        </div>
    );
}

export default ChargePanel;