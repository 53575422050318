import { Camera as camera_icon } from '@mui/icons-material';
import React, { useState, useCallback, useRef, useContext } from 'react';
import Webcam from 'react-webcam';
import './Camera.css'

import { AuthContext } from '../Authentication/Auth';
import { ref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import { storage } from '../../Firebase/FirebaseConfig';
import Timer from '../Timer/Timer';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import StopIcon from '@mui/icons-material/Stop';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';


const Camera = ({onFinishAssessmentCamera, visible, segundos_maximo_video}) => {
    const { currentUser } = useContext(AuthContext);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [uploading, set_uploading] = useState(false);
    const [upload_progress, set_upload_progress] = useState(0);
    const [camera_ready, set_camera_ready] = useState(false);

    const onCameraReady = () => {
        set_camera_ready(true);
    }

    const handleStartCaptureClick = useCallback(() => {
        if(webcamRef.current.stream){
            setRecordedChunks([]);
            setCapturing(true);
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm"
            });
            mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
            );
            mediaRecorderRef.current.start();
        }else{
            alert("Cámara no encontrada, \nPor favor verifique si ha aceptado los permisos del navegador. \nEspere unos segundos a que la cámara haya cargado completamente antes de presionar el botón 'Iniciar grabación'");
        }
    }, [webcamRef, setCapturing, mediaRecorderRef, setRecordedChunks]);
    
    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );
    
    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);
    
    const handleDownload = useCallback(() => {
        if (recordedChunks.length) {
            set_uploading(true);
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });

            const  storageRef = ref(storage, `/videos_presentacion/${currentUser.uid}/video.webm`);
            const uploadTask = uploadBytesResumable(storageRef, blob);

            uploadTask.on('state_changed', (snapshot)=>{
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                // console.log(progress);
                set_upload_progress(progress);
            },
            (err)=> console.error(err),
            ()=>{
                getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                    onFinishAssessmentCamera(url);
                });
            }
            )
            /* send data to firebase */
			// const storageRef = storage.ref(
            //     `/videos_presentacion/${currentUser.uid}/video.webm`
			// );
			// storageRef.put(blob).then(function (snapshot) {
			// 	snapshot.ref.getDownloadURL().then((downloadURL) => {
			// 		console.log(downloadURL);
            //         onFinishAssessmentCamera(downloadURL);
            //         // setTimeout(() => {
                        
            //         // }, 3000);
			// 	});
                
			// });
			/* end send data to firebase */
            // setRecordedChunks([]);
        }
    }, [recordedChunks]);
    

    const videoConstraints = {
        width: 640,
        height: 360,
        facingMode:"user"//{ exact: "environment" }//"user"
    };

    return (
        visible?
        <div className ="o-camera-container">
            {segundos_maximo_video>0&&<Timer seconds={segundos_maximo_video} onTimeFinalized={handleStopCaptureClick} capturing={capturing}></Timer>}
            <Webcam
                muted
                id="o-camera"
                audio={true}
                ref={webcamRef}
                videoConstraints={videoConstraints}
                mirrored = {true}
                onUserMedia={onCameraReady}
                //style={{ maxHeight: '60vh', borderRadius:"1rem"}}
            />
            
            

            <Card sx={{ width: '100%', maxHeight:'80%'}}>
             
                <div id='o-camera-buttons-container'>
                    {uploading &&
                        <Box sx={{ width: '100%', m:0}}>
                            <LinearProgress variant="determinate" value={upload_progress} />
                            <Typography  variant="body1" sx={{m:1}} color="text.secondary">{`Enviando video: ${upload_progress}%`}</Typography>
                        </Box>
                    }

                    {!uploading &&
                    (capturing ? (
                        
                        <Button onClick={handleStopCaptureClick} startIcon={<StopIcon/>} variant="outlined" color="error">Parar Grabación</Button>
                    ) : (
                        <Button onClick={handleStartCaptureClick} disabled={!camera_ready} startIcon={<PhotoCamera />} variant="outlined"> {recordedChunks.length > 0?'Grabar de nuevo' :'Grabar'} </Button>
                    ))}

                    {recordedChunks.length > 0 && !capturing && (
                        <LoadingButton 
                            onClick={handleDownload} 
                            variant="contained" sx={{mt:2}} 
                            component="span" 
                            endIcon={<SendIcon />}
                            loading={uploading}
                            loadingPosition="end">
                                {uploading? 'Enviando...' : 'Enviar y continuar'}
                        </LoadingButton>
                    )}
                </div>
            </Card>
        </div>
        :
        <></>
        
    );
}

export default Camera;