import React, {useState, useEffect} from "react";
import { FormControlLabel, 
    Radio, 
    RadioGroup, 
    FormControl, 
    FormHelperText, 
    FormLabel, 
    FormGroup,
    Button,
    Typography} from '@mui/material';

import ClosedQuestion from "./ClosedQuestion";

const questions_per_page = 3;

const FormQuestions = ({questions, onAnsweredQuestions})=>{

    const [categories_counter, set_categories_counter] = useState(0);

    const [categorias, set_categorias] = useState([...questions.categorias]);
    const [questions_array, set_questions_array] = useState(categorias[categories_counter].preguntas); //Conjunto de preguntas totales de una categoria
    const [questions_render, set_questions_render] = useState(questions_array.slice(0,questions_per_page)); //preguntas a renderizar
    const [enable_submit_button, set_enable_submit_button] = useState(false);

    const [respuestas, set_respuestas] = useState([]);

    useEffect(()=>{
        let empty_answers = []

        for (const categorie of categorias) {
            const new_cat = {
                categoria: categorie.categoria,
                respuestas:[]
            }

            for (const question of categorie.preguntas) {
                const resp = {pregunta: question.pregunta}
                new_cat.respuestas.push(resp);
            }

            empty_answers.push(new_cat);
        }

        // console.log('respuestas vacio', empty_answers);
        set_respuestas(empty_answers);
    }, []);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        
        //Verificar si hay alguna opción marcada como final para terminar el cuestionario
        // let final = false;
        // for(const cat of respuestas){
        //     for(const res of cat.respuestas){
        //         final = final || res.is_final;
        //     }
        // }
        let final = respuestas[categories_counter].respuestas.find(element => element.is_final);

        if(final){ //Si una opción final fue marcada
            onAnsweredQuestions(0, respuestas);
        }else{
            if(questions_array.length > questions_per_page){//Si aún hay preguntas de una categoría.
                const newQuestions = [...questions_array];
                newQuestions.splice(0, questions_per_page);
                set_questions_array(newQuestions);
                set_questions_render(newQuestions.slice(0, questions_per_page));
            }else{
                const new_cateorias_counter = categories_counter + 1;
    
                if(categorias.length > new_cateorias_counter){ //Si aún hay categorias por recorrer
                    const newQuestions = categorias[new_cateorias_counter].preguntas;
                    set_questions_array(newQuestions);
                    set_questions_render(newQuestions.slice(0,questions_per_page));
                    set_categories_counter(new_cateorias_counter);
                }else{
                    //Fin del cuestionario
                    let puntaje_total = 0;
                    for(const cat of respuestas){
                        for(const res of cat.respuestas){
                            puntaje_total += parseInt(res.puntaje, 10);
                            // console.log('pregunta: '+ res.pregunta + '   opción: '+ res.respuesta, 'puntaje: '+ res.puntaje);
                        }
                    }
    
                    onAnsweredQuestions(puntaje_total, respuestas);
                    // alert('terminado con puntaje: '+puntaje_total);
                }
                
            }
        }
        
        
    };

    const updateAnswer = (question, answer, score, is_final)=>{
        let res = [...respuestas];
        let question_res = res[categories_counter].respuestas.find(element => element.pregunta == question);
        if(question_res){
            question_res.pregunta = question;
            question_res.respuesta = answer;
            question_res.puntaje = score;
            if(is_final){
                question_res.is_final = is_final
            }else{
                delete question_res.is_final;
            }
        }else{
            //El elemento no se encontró
        }

        // console.log('respuestas', res);
        set_respuestas(res);
    }

    return(
        <form onSubmit={handleSubmit} >
            
            <Typography sx={{ fontWeight: 'bold'}} color="text.secondary" component="h1" variant="h5">
              {categorias[categories_counter].categoria}
            </Typography>

            {respuestas.length>0 &&(
                <FormGroup>
                    {questions_render.map((question,i) =>
                        <ClosedQuestion 
                            key={i}
                            question={question.pregunta} 
                            options={question.opciones}
                            updateAnswer={updateAnswer}>
                        </ClosedQuestion>
                    )}

                    <Button sx={{ mt: 1, mb:1 }} type="submit" variant="outlined">
                        Responder
                    </Button>
                </FormGroup>
            )}
            
        </form>
    );
}

export default FormQuestions;