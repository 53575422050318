import React, { useEffect } from "react";
import { FormControlLabel, 
    Radio, 
    Button,
    RadioGroup, 
    FormControl, 
    FormHelperText, 
    FormLabel} from '@mui/material';

const ClosedQuestion = ({question, options, updateAnswer, onAnsweredQuestion})=>{

    const [value, setValue] = React.useState(null);
    const [option_selected, set_option_selected] = React.useState(null);
    const [puntaje, setPuntaje] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Elige tu respuesta');

    useEffect(()=>setValue(null), []);

    const handleRadioLabelChange = (puntaje, label, is_final) => (event)=>{
        // console.log(puntaje);
        if(event.target.checked){
            setValue(event.target.value);
            setPuntaje(puntaje);
            set_option_selected(label);
            setHelperText(' ');
            setError(false);


            updateAnswer(question, label, puntaje, is_final);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(value == null || value == ''){
            setHelperText('Por favor selecciona una opción');
            setError(true);
        }else{
            setValue('');
            event.target.reset();
        }
    };

    return(
        <FormControl
            sx={{ m: 3, }}
            component="fieldset"
            error={error}
            variant="standard"
            required>

            <FormLabel component="legend" style={{textAlign:'left'}}>{question}</FormLabel>
            <RadioGroup
                required
                aria-label={question}
                name={question}
                value={value}
                // onChange={handleRadioChange}
                style={{textAlign:'left'}}
                >
                {options.map((option , i) => 
                    <FormControlLabel 
                        key={i} 
                        onChange={handleRadioLabelChange(option.puntaje, option.label, option.is_final)} 
                        value={option.label+'_'+question} control={<Radio required/>} label={option.label} />
                )}
            </RadioGroup>

            {/* <FormHelperText>{helperText}</FormHelperText> */}
            {/* <Button sx={{ mt: 1, mr: 1 }} onClick={handleSubmit} type="submit" variant="outlined">
                Responder
            </Button> */}

            
        </FormControl>
    );
}

export default ClosedQuestion;