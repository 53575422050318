import React, {useEffect, useState} from "react";
import './Avatar.css';

import Container from '@mui/material/Container';
import Grow from '@mui/material/Grow';

function Avatar({message ,set_avatar_loaded}) {
  const [avatar_ready, set_avatar_ready] = useState(false);

  useEffect(() => {
    function loadEmbedFile(){
      var sc = document.createElement('script');
      sc.type = "text/javascript";
      sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=8358293&js=0";
      sc.onload = function(){
        callEmebedFunction();
      }
      document.head.appendChild(sc);
    }
    
    function callEmebedFunction(){
      window.vh_sceneLoaded = () => {
        // console.log("Avatar Cargado");
        set_avatar_loaded(true);
        set_avatar_ready(true);
        // window.sayText(message,5,2,4); //window.sayText(message,2,2,3);
        // window.sayText('Hola, este es un audio de prueba amigo.',2,2,3);
      }

      var script = document.createElement('script');
      script.type = "text/javascript";
      script.innerHTML = 'AC_VHost_Embed(8358293,600,800,"",1,1,2746382,0,1,0,"6ehlEQRyWvOoOltnl41jiW8DeurIYoqI",0,1)';
      document.getElementById('embed_avatar').appendChild(script);
      
    }

    const scripts = document.head.getElementsByTagName('script');
    if(scripts.length > 0){
      var alreadyAdded = false;
      for(var i = 0; i < scripts.length;i++){
        if(scripts[i].src.includes("vhost_embed_functions_v4.php")){
          alreadyAdded = true;
          callEmebedFunction();
        }
        if(scripts.length === i+1 && !alreadyAdded){
          loadEmbedFile();
        }
      }
    }
    else{
      loadEmbedFile();
    }
  },[]);

  // useEffect(() => {
  //   if(avatar_ready && message!=''){
  //     window.stopSpeech();
  //     window.sayText(message,2,2,3);
  //   }
  // },[message]);

  return (
    
    <Container maxWidth="lg" >
        {/* <Grow 
            in={true} 
            style={{ transformOrigin: '0 0 0' }}
            {...({ timeout: 1000 })}>
            <img id="o-video-avatar" src="https://www.w3schools.com/html/img_girl.jpg"/>
            
        </Grow> */}
        <div id="embed_avatar" className="Comment"></div>
        {/* <a className="selected_support" 
          href="javascript:sayText('¿Cuál es tu ultimo nivel de estudios alcanzado?',2,2,3);"> 
          Dí algo para probar la funcionalidad del avatar
        </a> */}
    </Container>
    
    
  );
    
}

export default Avatar;