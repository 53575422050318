import React, {useEffect} from "react";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const MessageAvatarForm = ({message, onMessageSubmit, finish})=>{

    const handleSubmit = (event) => {
        event.preventDefault();
        onMessageSubmit(); 
    };

    useEffect(() => {
        if(message!=''){
            window.stopSpeech();
            window.sayText(message,2,2,3); //activar ahoraaaaaaaaaaaaaaaaa 223 124
        }
    },[message]);

    return(
        <form onSubmit={handleSubmit} style={{height:'100%', display:'flex', alignItems:'center'}}>
            <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                error={false}
                variant="standard">

                <FormLabel component="legend" style={{textAlign:'left'}}>{message}</FormLabel>
                {finish && (<Link href="https://trabajaconnosotros.ezenza.co/trabajaconnosotros/index_cualitas.php" target="_blank"
                    sx={{textAlign:'left', mt:1, mb:1}}>
                    Trabaja con nosotros ¡Regístrate Aquí!
                </Link>)}
                <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    {finish? 'Finalizar' : 'Continuar'}
                </Button>

                
            </FormControl>
        </form>
    );
}

export default MessageAvatarForm;