import React from "react";
import { Typography,Container, FormHelperText } from '@mui/material';

import './Footer.css'

import Copyright from "../Copyright/Copyright";

function Footer () {
    return(
    <Container sx={{ bgcolor: 'primary.main' }} maxWidth="xl"  id="o-footer-container">
        <div id="o-footer-logos" style={{color:'white'}}>
            <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/Images/Logotipo_Scenario_002.png`} height="40" alt=""/>
            <Typography sx={{mx:2}}>
                Powered by
            </Typography>
            <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/Images/LOGOS_NEGATIVO-02.png`} height="50" alt=""/>
        </div>
        
        <Copyright style={{color:'gray'}}></Copyright>
    </Container>);
}

export default Footer;