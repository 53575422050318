import React from "react";
import { Typography, Container, Alert , Button} from '@mui/material';
import './CompletedAssessment.css'

import { auth } from "../../Firebase/FirebaseConfig";
import { signOut } from 'firebase/auth'

function CompletedAssessment () {
    const handleSignOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    };

    return(
    <Container maxWidth="xl" sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems:'center',
        width:'90%',
        height:'80%'
      }}>
        <Alert severity="success">La prueba ha sido completada. Te agradecemos mucho el tiempo invertido en ejercicio, si continuas en nuestro proceso uno de nuestros analistas te contactara prontamente y te informara las siguientes etapas del proceso. De igual manera si no cumplieses con el perfil solicitado tu Hoja de Vida estará en nuestra base de datos para futuros procesos.</Alert>
        <Button sx={{ mt: 3, mr: 1 }} onClick={handleSignOut} variant="outlined">
            Salir
        </Button>
    </Container>);
}

export default CompletedAssessment;