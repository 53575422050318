import React from 'react'
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Timer = ({ seconds, onTimeFinalized, capturing}) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [minutos, setMinutos] = useState(Math.floor((seconds / (60)) % 60));
    const [segundos, setSegundos] = useState(Math.floor((seconds) % 60));
    const [paused, setPaused] = useState(true);

  
    useEffect(()=>{

      if(capturing){
        reanudar();
      }else{
        pauseTime();
        setTimeLeft(seconds);
        setMinutos(Math.floor((seconds / (60)) % 60));
        setSegundos(Math.floor((seconds) % 60));
      }

      
    }, [capturing]);

    useEffect(() => {
      
      if (!timeLeft){
        onTimeFinalized()
        return;
      } 

      const intervalId = setInterval(() => {
        if(!paused){
          setTimeLeft(timeLeft - 1);
          setMinutos(Math.floor(((timeLeft-1) / (60)) % 60));
          setSegundos( Math.floor((timeLeft-1) % 60));
        }
        
      }, 1000);
  
      return () => clearInterval(intervalId);

    }, [timeLeft]);

    const pauseTime = ()=>{
      // if(paused){
      //   setTimeLeft(timeLeft-1);
      //   setPaused(false)
      // }else{
      //   setTimeLeft(timeLeft+1);
      //   setPaused(true)
      // }
      setTimeLeft(timeLeft+1);
      setPaused(true)
  }


    const reanudar = ()=> {
      if(paused){
        setTimeLeft(timeLeft-1);
        setPaused(false)
      }
    }
  
    return (
      <div>
        {/*<h1>{minutos>0? minutos + "min " + segundos + "seg" : segundos + "seg"}</h1>*/}
        {timeLeft!=0?
        <div>
          <div style={{display:"flex"}}>
            <h1 style={{color: "#457b9d", margin:0}}>{ minutos  + " : " + (segundos<10? "0" + segundos : segundos) }</h1>
          </div>
          {/* <CircularProgress variant="determinate" value={parseInt((segundos/seconds)*100, 10)} /> */}
          {/* <p style={{margin:0,}}>Tiempo restante</p> */}
        </div>:
        <h3 style={{color: "#b23b3b", /* height: "1rem" */}}>{  "Tiempo completo"}</h3>}
        
      </div>
    );
}

export default Timer;