import React, {useEffect, useState} from "react";
import './App.css';
import {
	HashRouter as Router,
	Switch,
	Route,
  Redirect,
} from 'react-router-dom';
 
import Login from "./Views/Login";
import Assessment from "./Views/Assessment";
import NotFound404 from "./Views/NotFound404";
import PrivateRoute from "./Components/Authentication/PrivateRoute";
import { AuthProvider } from "./Components/Authentication/Auth";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

// const theme = createTheme({
//   palette: {
//     type: "dark",
//     primary: {
//       light: '#757ce8',
//       main: purple[500],
//       dark: '#002884',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7961',
//       main: '#f44336',
//       dark: '#ba000d',
//       contrastText: '#000',
//     },
//   },
// });

const theme = createTheme({
  palette: {
    mode: 'light',//'dark',
    primary: {
      main:'#001e3c',
    },
  },
});


function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <div className="App">
            <Switch>
              <Redirect exact from="/" to="/Login" />
              <Route exact path="/Login" component={Login}/>
              <PrivateRoute exact path='/assessment' component={Assessment}/>
              <Route path="*"> <NotFound404 /> </Route>
            </Switch>  
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
    
  );
    
}

export default App;
